import { App } from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { addItem, removeItem } from "@/composables/items";
import eventbus from "@/eventbus";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    static loadingRequests: any[] = [];

    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

        ApiService.vueInstance.axios.interceptors.request.use((config) => {
            if (config.params && config.params.hideLoading) {
                // Remove helper to hide loading animation
                delete config.params.hideLoading;
            } else {
                // Add path to loading
                addItem(ApiService.loadingRequests, config.url);
                if (ApiService.loadingRequests.length === 1) {
                    // Show loading
                    eventbus.emit("show-loading");
                }
            }

            return config;
        });

        ApiService.vueInstance.axios.interceptors.response.use(
            (response) => {
                if (process.env.NODE_ENV && process.env.NODE_ENV.trim() === "development" && (process.env.VUE_APP_DEBUG_RESPONSES || "yes") === "yes") {
                    console.log("Response", response);
                }

                if (response && response.config && response.config.url) {
                    // Remove path from loading
                    removeItem(ApiService.loadingRequests, response.config.url);
                    if (ApiService.loadingRequests.length === 0) {
                        // Hide loading
                        eventbus.emit("hide-loading");
                    }
                }

                return response;
            },
            (error) => {
                if (process.env.NODE_ENV && process.env.NODE_ENV.trim() === "development") {
                    console.log("Error", error);
                }

                if (error && error.config && error.config.url) {
                    // Remove path from loading
                    removeItem(ApiService.loadingRequests, error.config.url);
                    if (ApiService.loadingRequests.length === 0) {
                        // Hide loading
                        eventbus.emit("hide-loading");
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    /**
     * @description set the default HTTP request headers
     */
    // prettier-ignore
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
        ApiService.vueInstance.axios.defaults.headers.common["x-current-role"] = "" + JwtService.getRole();
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(resource: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, config);
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static get(resource: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, config);
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param data: object
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(resource: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, data, config);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param data: any
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(resource: string, slug: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, data, config);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param data: any
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(resource: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, data, config);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource);
    }
}

export default ApiService;
