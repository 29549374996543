export const addItem = (items: any[], item: any): any[] => {
    if (items.indexOf(item) < 0) {
        items.push(item);
    }
    return items;
};

export const removeItem = (items: any[], item: any): any[] => {
    const index = items.indexOf(item);
    if (index > -1) {
        items.splice(index, 1);
    }
    return items;
};
