import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () => import("@/views/authentication/basic-flow/PasswordReset.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/authentication/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/authentication/Error500.vue"),
    },
];

export default routes;
