
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import Loading from "vue3-loading-overlay";
import eventbus from "@/eventbus";

export default defineComponent({
    setup() {
        const store = useStore();

        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

            nextTick(() => {
                initializeComponents();
            });
        });
    },

    components: {
        Loading,
    },

    data() {
        return {
            showLoading: false,
        };
    },

    created() {
        eventbus.on("show-loading", () => {
            this.showLoading = true;
        });

        eventbus.on("hide-loading", () => {
            this.showLoading = false;
        });
    },

    mounted() {
        // Add css class to display loader only over content
        const element = document.getElementsByClassName("vld-overlay")[0];
        element.classList.add("wrapper");
    },
});
