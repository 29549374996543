const ID_TOKEN_KEY = "id_token" as string;
const ID_ROLE_KEY = "id_role" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRole = (): string | null => {
    return window.localStorage.getItem(ID_ROLE_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRole = (role: string): void => {
    window.localStorage.setItem(ID_ROLE_KEY, role);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRole = (): void => {
    window.localStorage.removeItem(ID_ROLE_KEY);
};

export default { getToken, getRole, saveToken, saveRole, destroyToken, destroyRole };
